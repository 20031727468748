import React from "react"
import styled from "styled-components"

import AudioPlayer from "../components/AudioPlayer"

const StyledPage = styled.div`
    // Temporary styles on the page
    // Using while building the audio player
    height: 100vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    svg {
        max-height: 2rem;
    }
`

export default function Index() {
    return (
        <StyledPage>
            <AudioPlayer />
        </StyledPage>
    )
}